@import "~antd/dist/antd.css";
/* 
App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

:root {
  --viewer-panel-bg-color: #3d3d3d;
  --viewer-panel-text-color: #eeeeee;
}

.viewer-panel [aria-label] {
  position: relative;
}

.viewer-panel [aria-label]:after {
  content: attr(aria-label);
  display: none;
  position: absolute;
  top: 110%;
  left: 10px;
  z-index: auto;
  pointer-events: none;
  padding: 0.25rem;
  line-height: 15px;
  white-space: nowrap;
  text-decoration: none;
  text-indent: 0;
  overflow: visible;
  font-size: 0.75rem;
  font-weight: normal;
  color: var(--viewer-panel-text-color);
  text-shadow: 1px 0 1px #888;
  background-color: var(--viewer-panel-bg-color);
  border-left: 0.125rem solid var(--primary);
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);
}

.viewer-panel [aria-label]:hover:after /**, [aria-label]:focus:after**/ {
  display: block;
}

.viewer-panel {
  color: var(--viewer-panel-text-color);
  background-color: var(--viewer-panel-bg-color);
  padding: 0.5rem;
}

.viewer-panel h1,
h2 {
  color: var(--viewer-panel-text-color);
}

.viewer-panel .btn-edit-text {
  padding: 0;
  background: rgb(1, 1, 1, 0);
  border: none;
  color: var(--viewer-panel-text-color);
  min-height: 0;
  width: 1.5rem;
}

.viewer-panel .btn-edit-text:focus,
.btn-edit-text:active {
  outline: none !important;
  box-shadow: none;
}

.viewer-panel .spinner-border {
  color: var(--primary);
}

.context-panel {
  position: absolute;
  width: 12rem;
  top: 1rem;
  left: 1rem;
  border-radius: 0.25rem;
}

.context-panel button {
  width: 100%;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.control-panel {
  position: absolute;
  top: 1rem;
  left: 1rem;
  border-radius: 0.25rem;
}

.control-panel button {
  margin: 0.25rem;
}

.view-cube-panel {
  position: absolute;
  top: 1rem;
  right: 20rem;
  background: rgba(0, 0, 0, 0);
}

.viewerThree {
  height: 100%;
}

.viewerThree > canvas {

}

canvas {
  display: block;
  height: 100% !important;
  object-fit: cover;
  top: 0;
  z-index: -1;
  position: absolute;
}

body {
  /* color: #000;
  font-family:Monospace;
  font-size:13px; */
  text-align: center;
  /* font-weight: bold; */

  /* background-color: #fff; */
  /* margin: 0px; */
  overflow: hidden;
}

#info {
  color: #000;
  position: absolute;
  top: 0px;
  width: 100%;
  padding: 5px;
}

a {
  color: red;
}

#view-cube-canvas {
  outline: none !important;
  overflow: hidden;
}
